const {
  ItemView,
  View
} = require('Marionette');
const { find } = require('underscore');
const UIKit = require('@training/widgets/UIKit');

const I18n = require('@common/libs/I18n');

const FileHelpers = require('@common/libs/file/FileHelpers');
const UrlHelpers = require('@common/libs/helpers/app/UrlHelpers');
const ViewHelpers = require('@common/libs/helpers/app/ViewHelpers');

const ContextModel = require('@common/data/models/ContextModel');

const AppMenuConfigFactory = require('@common/modules/base/AppMenuConfigFactory');
const SessionMenuConfigFactory = require('@common/modules/base/SessionMenuConfigFactory');
const MainLayoutAPI = require('@common/modules/main/MainLayoutAPI');

const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');

const DataLoadingSpinnerContextConfig = require('@common/components/dataLoadingSpinner/DataLoadingSpinnerContextConfig');
const DataLoadingSpinnerControllerDefinitionFactory = require('@common/components/dataLoadingSpinner/DataLoadingSpinnerControllerDefinitionFactory');

const PageLayoutContextConfig = require('@training/apps/training/pageLayout/PageLayoutContextConfig');
const PageTitleBarContextConfig = require('@training/apps/training/pageTitleBar/PageTitleBarContextConfig');

const PageLayoutControllerDefinitionFactory = require('@training/apps/training/pageLayout/PageLayoutControllerDefinitionFactory');
const PageTitleBarDefinitionFactory = require('@training/apps/training/pageTitleBar/PageTitleBarDefinitionFactory');

const {
  HUB_SEQUENCE,
  FLOW_SEQUENCE,
  DZ_SUPERUSER_SEQUENCE,
  DZ_GUEST_SEQUENCE
} = require('@common/modules/base/TrainingMenuConfigSequenceEnum');

const TrainingMenuConfigFactory = require('@common/modules/base/TrainingMenuConfigFactory');
const { ChatDefinitionFactory } = require('@training/apps/chat/ChatDefinitionFactory');

const normalizeViewControllerDefinition = (viewConfigData) => {
  if (viewConfigData instanceof View) {
    return {
      viewDefinition: viewConfigData
    };
  }

  return viewConfigData;
};

const FlashAdapter = (mainLayout) => {
  return {
    show(cssClass = 'info', messages = [], dismissDelay) {
      const message = [].concat(messages).join('</br>');
      mainLayout.displayFlash({
        cssClass,
        message,
        dismissDelay
      });
    },

    success(message, timeout) {
      this.show('success', message, timeout);
    },

    error(message, timeout) {
      this.show('error', message, timeout);
    },

    info(message, timeout) {
      this.show('info', message, timeout);
    },

    warning(message, timeout) {
      this.show('warning', message, timeout);
    },

    clear() {
      mainLayout.clearFlash();
    }
  };
};

const getPageView = (mainLayout) => {
  return mainLayout != null ? mainLayout.findControllerById('page-view').getView() : undefined;
};

const getDetailsView = (mainLayout) => {
  return mainLayout != null ? mainLayout.findControllerById('details-view').getView() : undefined;
};

const getTrainingLayersLayout = (mainLayout) => {
  return mainLayout != null ? mainLayout.findControllerById('training-mobile-layers').getView() : undefined;
};

class TrainingLayoutAdapter {

  constructor(options = {}) {
    ({
      viewControllerFactory: this.viewControllerFactory
    } = options);

    this.mainLayout = MainLayoutAPI(options);

    this.contextModel = new ContextModel();
    this.contextModel.setContextConfig( DataLoadingSpinnerContextConfig );
    this.contextModel.setContextConfig( PageLayoutContextConfig );
    this.contextModel.setContextConfig( PageTitleBarContextConfig );

    DataLoadingSpinnerContextConfig.setContentControllerDefinition(this.contextModel, PageLayoutControllerDefinitionFactory(this.contextModel) );
    PageLayoutContextConfig.setPageHeaderControllerDefinition(this.contextModel, PageTitleBarDefinitionFactory(this.contextModel) );

    this.flash = FlashAdapter(this.mainLayout);
  }

  get actionBar() {
    return getPageView(this.mainLayout) && getPageView(this.mainLayout).actionBar || null;
  }

  get detailActionBar() {
    return getDetailsView(this.mainLayout) && getDetailsView(this.mainLayout).actionBar || null;
  }

  initializeMenu({
    authSession,
    sessionController
  } = {}) {
    this.menuHubFactory = TrainingMenuConfigFactory({
      authSession,
      getActivityTypes: () => {
        return sessionController.getHubController().getAvailableTiles();
      },
      getConfigSequence: () => {
        if (authSession.user.isSuperuser()) {
          return DZ_SUPERUSER_SEQUENCE;
        } else if (authSession.user.isGuestUser()) {
          return DZ_GUEST_SEQUENCE;
        } else if (sessionController.isHubMenuEnabled()) {
          return HUB_SEQUENCE;
        }
        return FLOW_SEQUENCE;

      }
    });

    this.appMenuFactory = AppMenuConfigFactory(authSession);

    this.menuSessionFactory = SessionMenuConfigFactory(() => {
      sessionController.endSessionAndWipe();
    });

    this.updateMenu();
  }

  initializeHeaderSettings() {
    this.mainLayout.toggleHeaderLogo();
    this.mainLayout.enableHeaderBackButton(TenantPropertyProvider.get().getProperty('showInAppBackButton'));
  }

  initializeTainingPageView() {
    this.mainLayout.showPageDefinition( DataLoadingSpinnerControllerDefinitionFactory(this.contextModel) );
  }

  registerBonusCharacterUpdater() {
    ViewHelpers.bonuscharacter = window.apps.auth.session.user.getCoachType();

    // Set up user's coach on coach changes
    window.apps.auth.session.user.on('change:coach', (user) => {
      // update the user's coach within the view helper
      ViewHelpers.bonuscharacter = user.getCoachType();
    });
  }

  registerDownloadLinkHandler() {
    FileHelpers.registerDownloadLinkHandler(document, () => {
      this.flash.error(I18n.t('flash.downloadNotSupported'));
    });
  }

  show(region, showOptions = {}) {
    this.mainLayout.show(region, showOptions);
  }

  updateMenu() {
    const menuItems = [
      ...this.menuHubFactory(),
      ...this.appMenuFactory(),
      ...this.menuSessionFactory()
    ];

    let menuItemId = TrainingMenuConfigFactory.mapHashToConfigId( UrlHelpers.getLocationHash() || '' );
    const existingItemConfig = find(menuItems, (item) => {
      return item.configId === menuItemId;
    });

    if (existingItemConfig == null) {
      menuItemId = TrainingMenuConfigFactory.mapHashToConfigId('#daily-activities');
    }

    this.mainLayout.setMenuItemDefinitions(menuItems, { merge: false });
    this.mainLayout.setSelectedMenuItem( menuItemId );
  }

  resetMenu() {
    this.mainLayout.setMenuItemDefinitions([]);
    this.mainLayout.setSelectedMenuItem('');
  }

  showMedia(media) {
    this.mainLayout.showMedia(media);
  }

  dismissMedia() {
    this.mainLayout.dismissMedia();
  }

  // Set a subview in .contentwrapper
  // Supports View instances and ViewControllerDefinitions
  setView(viewConfigData, transition = UIKit.View.Transitions.FADE) {
    if (this.menuHubFactory != null) {
      this.updateMenu();
    }

    this.showHeaderUserProfileIcon(apps.auth.session.user);
    this.resetViews();

    const controllerDefinition = normalizeViewControllerDefinition(viewConfigData);
    const viewController = this.viewControllerFactory.create(controllerDefinition);
    viewController.inflateView();
    const view = viewController.getView();

    getPageView(this.mainLayout).setSubviewIn(view, { transition });
  }

  toggleLayoutHeaderDesktopHideable(toggle = false) {
    this.mainLayout.toggleHeaderDesktopHideable(toggle);
  }

  togglePageHeader(toggle = true) {
    PageLayoutContextConfig.toggleHeaderVisible(this.contextModel, toggle);
  }

  toggleMenuMinimalDisplay(toggle = false) {
    this.mainLayout.toggleMenuMinimalDisplay(toggle);
  }

  toggleMenuHiddenDisplay(toggle = false) {
    this.mainLayout.toggleMenuHiddenDisplay(toggle);
  }

  setPageTitleBarLeftDefinition(controllerDefinition, options) {
    PageTitleBarContextConfig.setLeftDefinition(this.contextModel, controllerDefinition, options);
  }

  setPageTitleBarRightDefinition(controllerDefinition, options) {
    PageTitleBarContextConfig.setRightDefinition(this.contextModel, controllerDefinition, options);
  }

  setPageTitleBarBailDefinition(controllerDefinition, options) {
    PageTitleBarContextConfig.setBailDefinition(this.contextModel, controllerDefinition, options);
  }

  togglePageSpinnerService(toggle) {
    DataLoadingSpinnerContextConfig.toggleEnabled(this.contextModel, toggle);
  }

  showSpinner() {
    DataLoadingSpinnerContextConfig.showSpinner(this.contextModel);
  }

  hideSpinner() {
    DataLoadingSpinnerContextConfig.hideSpinner(this.contextModel);
  }

  showEmptyView() {
    this.setView(new ItemView({ template: false }), UIKit.View.Transitions.NONE);
  }

  setLoginDisclaimer() {
    getPageView(this.mainLayout).footer.setLoginDisclaimer();
  }

  setGeneralDisclaimer() {
    getPageView(this.mainLayout).footer.setGeneralDisclaimer();
  }

  toggleFooter(toggle) {
    getPageView(this.mainLayout).toggleFooter(toggle);
  }

  toggleFullPage(toggle) {
    const pageView = getPageView(this.mainLayout);
    const isFullPage = pageView.isFullPage();
    let shouldToggle = toggle;

    if (shouldToggle == null) {
      shouldToggle = !isFullPage;
    }

    pageView.toggleFullPage(shouldToggle);
    pageView.toggleActionBar(!shouldToggle);
  }

  toggleFullScreen(toggle) {
    this.mainLayout.toggleFullScreen(toggle);
  }

  setTitle(title) {
    document.title = title;
  }

  addSelectableMenuItems(items, options) {
    this.mainLayout.addSelectableMenuItems(items, options);
  }

  resetLeftHeaderView() {
    this.mainLayout.setLeftHeaderViewDefinition();
  }

  showHeaderBackButton(handler) {
    this.mainLayout.showHeaderBackButton(handler);
  }

  showChat() {
    const hasPendingUserAgreements = window.apps?.auth?.session?.get('pendingAgreements') > 0;
    if (hasPendingUserAgreements) {
      return;
    }
    const isLoggedIn = window.apps?.auth?.session?.user?.isLoggedIn();
    if (!isLoggedIn) {
      return;
    }
    const hasChatAccess = window.apps?.auth?.session?.user?.hasChatAccess();
    if (!hasChatAccess) {
      return;
    }
    const chatEnabled = TenantPropertyProvider.get().getProperty('chatEnabled');
    if (!chatEnabled) {
      return;
    }
    // pass onCloseChat callback to close the chat manually when mobile notification is received
    this.mainLayout.setChatHeaderControllerDefinition(ChatDefinitionFactory(this.onCloseChat.bind(this)));
  }

  onCloseChat(callback) {
    if (callback) {
      this.callback = callback;
    }
  }

  showHeaderUserProfileIcon(user) {
    this.mainLayout.showHeaderUserProfileIcon(user);
  }

  // Set the view as the modal container
  presentModal(modalView, options = {}) {
    const visibleView = this._visiblePageView || getPageView(this.mainLayout);
    visibleView.presentModal(modalView, options);
  }

  setViewInModal(modalView, transition = UIKit.View.Transitions.NONE) {
    const visibleView = this._visiblePageView || getPageView(this.mainLayout);
    visibleView.setViewInModal(modalView, transition);
  }

  dismissModal(complete) {
    if (this.callback) {
      this.callback();
    }
    const visibleView = this._visiblePageView || getPageView(this.mainLayout);
    visibleView.dismissModal(complete);
  }

  // Drill into a details view
  pushDetailsView(view, transition) {
    if (apps.base.checkDoubleSubmit()) {
      return;
    }

    const trainingLayers = getTrainingLayersLayout(this.mainLayout);
    const detailsView = getDetailsView(this.mainLayout);
    const pageView = getPageView(this.mainLayout);

    this._showingDetailsView = Boolean(view);
    this._visiblePageView = detailsView;

    switch (transition) {
      case UIKit.View.Transitions.FADE:
        pageView.fade([0, 'ease', 1], {
          complete: () => {
            trainingLayers.toggleOverlay(true);
            detailsView.setSubviewIn(view);
            detailsView.fade([1, 'ease', 0]);
          }
        });
        break;
      default:
        pageView.getFadingEl().css('opacity', '0');
        detailsView.getFadingEl().css('opacity', 1);
        trainingLayers.toggleOverlay(true);
        detailsView.setSubviewIn(view);
    }
  }

  popDetailsView() {
    if (!this._showingDetailsView) {
      return;
    }

    const trainingLayers = getTrainingLayersLayout(this.mainLayout);
    const detailsView = getDetailsView(this.mainLayout);
    const pageView = getPageView(this.mainLayout);

    this._visiblePageView = pageView;

    detailsView.actionBar?.setActionButton(null);
    detailsView.fade([0, 'ease', 1], {
      complete: () => {
        this._showingDetailsView = false;
        trainingLayers.toggleOverlay(false);
        detailsView.removeSubview();
        pageView.fade([1, 'ease', 0]);
      }
    });
  }

  resetViews() {
    this.dismissModal();
    this.popDetailsView();
  }
}

module.exports = TrainingLayoutAdapter;
