const $os = require('detectOS');
const _ = require('underscore');
const logging = require('logging');
require('jquery.velocity');

const StatefulRegion = require('@common/libs/UI/StatefulRegion');
const { extendLightboxOptionsWithAndroidBackDismiss } = require('@common/libs/helpers/app/NativeBridgeHelpers');

require('@common/libs/behaviors/impressionTracker/ImpressionTracker');
const Scrollable = require('@common/libs/behaviors/scrollable/Scrollable');

const AdjustableLayout = require('@training/apps/main/views/AdjustableLayout');
const ActionBar = require('@training/apps/main/views/ActionBar');
const FooterView = require('@training/apps/main/views/FooterView');

class PageView extends AdjustableLayout {

  behaviors() {
    return Object.assign({}, super.behaviors(), {
      ImpressionTrackerViewPort: {
        root: '#page-view',
        timeOffset: window.apps.auth.session.serverTimeOffset
      }
    });
  }

  getTemplate() {
    return `
    <div class="adjustable-container">

      <div class="adjustable-content-wrapper">

        <div class="adjustable-content"></div>

        <div class="actionbar page-action-bar"></div>
        <div class="actionbar-placeholder hidden"></div>

      </div>

      <footer></footer>

    </div>`;
  }

  ui() {
    return Object.assign({}, super.ui(), {
      actionBar: '.actionbar',
      actionBarPlaceholder: '.actionbar-placeholder',
      footer: 'footer'
    });
  }

  regions() {
    return {
      [AdjustableLayout.CONTENT_SELECTOR]: {
        regionClass: StatefulRegion,
        selector: AdjustableLayout.CONTENT_SELECTOR
      }
    };
  }

  viewStateEvents() {
    return Object.assign({}, super.viewStateEvents(), {
      'change:isFullPage': 'onChangeIsFullPage',
      'change:showFooter': 'onChangeShowFooter',
      'change:isActionBarVisible change:isActionBarFixed': 'onActionBarStateChange',
      'change:fixedFooter': 'onChangeFixedFooter'
    });
  }

  getDefaultViewState() {
    return Object.assign({}, super.getDefaultViewState(), {
      isFullPage: null,
      showFooter: null,
      fixedFooter: null,
      isActionBarVisible: false,
      isActionBarFixed: false,
      scrollTop: 0
    });
  }

  onRender() {
    super.onRender();

    this._renderActionBar();
    this._renderFooter();
  }

  onScroll(data) {
    super.onScroll(data);

    this._adjustActionBar(data);
  }

  onChangeIsFullPage(model, isFullPage) {
    this.getContainer().toggleClass('full-page ax-container--full-page', isFullPage);
  }

  onActionBarStateChange(model) {
    const {
      isActionBarFixed,
      scrollData: {
        scrollVerticalOverflow
      }
    } = model.toJSON();

    logging.info('PageView - onActionBarStateChange - isActionBarFixed', isActionBarFixed);
    this.actionBar.toggleFixed(isActionBarFixed);

    logging.info('PageView - onActionBarStateChange - toggleScrollBarBuffer', isActionBarFixed && scrollVerticalOverflow);
    this.actionBar.toggleScrollBarBuffer(isActionBarFixed && scrollVerticalOverflow);

    logging.info('PageView - onActionBarStateChange - _moveActionBar', isActionBarFixed);
    this._moveActionBar(isActionBarFixed);

    logging.info('PageView - onActionBarStateChange - _toggleActionBarPlaceholder', isActionBarFixed && !($os.mobile || this.isFullPage()));
    this._toggleActionBarPlaceholder(isActionBarFixed && !($os.mobile || this.isFullPage()));

    logging.info('PageView - onActionBarStateChange - updateContainerHeight');
    // Ensure the container gets adjusted to conform to the new page layout.
    this.updateContainerHeight();
  }

  onChangeShowFooter(model, showFooter) {
    if (this.footer != null) {
      if (showFooter) {
        this.footer.show();
      } else {
        this.footer.hide();
      }
    }
  }

  onChangeFixedFooter(model, fixedFooter) {
    if (this.footer != null) {
      this.footer.toggleFixed(fixedFooter);
    }
  }

  onDestroy() {
    this.footer.destroy();
    this.actionBar.destroy();
  }

  calculateContainerHeight() {
    // Subtract the actionBar's height when it's fixed since it's not in the content anymore
    // and we want the content to sit above it completely height wise and not have content scroll behind it.
    const actionBarHeight = this._getFixedActionBarHeight(this.isActionBarFixed());
    return actionBarHeight === 0 ? '' : `calc(100% - ${ actionBarHeight }px)`;
  }

  getScrollContentDimensions() {
    // Since the footer is a sibling to the content wrapper we need to sum the 2 heights
    const footerHeight = this._getFixedFooterHeight(!this.isFooterFixed());
    const wrapperHeight = this.getOuterHeight(this.getContentWrapper(), true);
    const wrapperWidth = this.getOuterWidth(this.getContentWrapper(), true);

    return {
      contentHeight: wrapperHeight + footerHeight,
      contentWidth: wrapperWidth
    };
  }

  // This calculates the height of the page content irrespective of whether the actionBar is inlined or fixed
  // to determine the action bar would be fixed if that height is greater than the max allowed height.
  // The footer is excluded so that the PageView's overflow state kicks in when the bottom of the action bar is reached.
  // Do not get this confused with the scroll content height calculated above.
  calculateContentHeight() {
    return this.getOuterHeight(this.getContentWrapper(), true);
  }

  toggleFullPage(toggle = true) {
    this._viewState.set('isFullPage', toggle);
  }

  toggleActionBar(showActionBar = true) {
    // side effect alert - show/hide does triggerAdjustment which will cause everything to recalculate if the
    // action bar should be fixed or not
    if (showActionBar) {
      this.actionBar.show();
    } else {
      this.actionBar.hide();
    }
  }

  isActionBarFixed() {
    return this.actionBar.isFixed();
  }

  isFullPage() {
    return this._viewState.get('isFullPage');
  }

  toggleFooter(toggle = true) {
    this._viewState.set('showFooter', toggle);
  }

  isFooterShowing() {
    return this._viewState.get('showFooter');
  }

  fixFooter(fix) {
    this._viewState.set('fixedFooter', fix);
  }

  isFooterFixed() {
    return this._viewState.get('fixedFooter');
  }

  getFadingEl() {
    return this.getContentWrapper();
  }

  onBeforeSetSubviewIn(view) {
    this.dismissModal();

    Scrollable.scrollToTop(this, 0);

    this.fixFooter(true);

    view.actionBar = this.actionBar;
  }

  // Set the view as the modal container
  presentModal(modalView, optionsParam = {}) {
    // The default View only catches ancestor elements in the onLoad/onClose functions
    // that are scrollable but in this case we want the modal outside the scrollable element
    // to avoid page size restrictions that can muck up the modal overlay.
    let options = optionsParam;

    options = $.extend(true, {}, options, {
      onLoad: _.wrap(options.onLoad, (onLoad = () => {}) => {
        this.getContainer().css('overflow', 'hidden');
        onLoad();
      }),
      onClose: _.wrap(options.onClose, (onClose = () => {}) => {
        this.getContainer().css('overflow', '');
        onClose();
      })
    });

    options = extendLightboxOptionsWithAndroidBackDismiss(modalView, options);

    super.presentModal(modalView, options);
  }

  /* Private methods */

  _renderActionBar() {
    this.actionBar = new ActionBar({
      el: this.ui.actionBar
    });

    this.actionBar.render();

    this.listenTo(this.actionBar, 'action:button:update', () => {
      this._adjustActionBar(this._viewState.get('scrollData'));
    });
  }

  _renderFooter() {
    this.footer = new FooterView({
      el: this.ui.footer
    });
    this.footer.render();
    this.footer.setGeneralDisclaimer();
    this.toggleFooter(!$os.mobile);
  }

  _getFixedFooterHeight(isFixed) {
    if (isFixed) {
      return _.result(this, 'footer.getHeight', 0);
    }

    return 0;
  }

  _getFixedActionBarHeight(isFixed) {
    if (isFixed) {
      return this.actionBar.getHeight();
    }

    return 0;
  }

  _adjustActionBar(scrollData) {
    const fixedFooter = this._calcIsFooterFixed(scrollData);
    const isActionBarFixed = this._calcIsActionBarFixed(scrollData);

    this._viewState.set({
      fixedFooter,
      isActionBarFixed
    });
  }

  // Determines if the scrollBottom of the container is beyond the height of the contentWrapper which
  // indicates the actionbar needs to stick to the bottom of the page.
  _calcIsActionBarFixed(scrollData) {
    const actionBarHeight = this._getFixedActionBarHeight(this.isActionBarFixed()) * 1000;
    const contentWrapperHeight = this.getContentHeight() * 1000;
    const isActionBarVisible = this.actionBar.isVisible();
    const isActionBarPopulated = this.actionBar.hasButtons();

    const scrollTop = scrollData.scrollTop * 1000;
    const containerHeight = scrollData.containerHeight * 1000;

    const scrollBottomActionBarPosition = containerHeight + scrollTop + actionBarHeight;

    const atActionBarBottom = scrollBottomActionBarPosition < contentWrapperHeight;

    return isActionBarVisible && isActionBarPopulated && ($os.mobile || this.isFullPage() || atActionBarBottom);
  }

  _calcIsFooterFixed(scrollData) {
    const footerHeight = this._getFixedFooterHeight(this.isFooterFixed()) * 1000;
    const availableHeight = this.getAvailableHeight() * 1000;
    const scrollContentHeight = scrollData.contentHeight * 1000;
    const contentWithFooterHeight = scrollContentHeight + footerHeight;

    return contentWithFooterHeight <= availableHeight;
  }

  _moveActionBar(fixed) {
    // Remove the actionbar so it can be re-parented
    this.actionBar.$el.detach();

    if (fixed) {
      // Move actionbar outside the scrollable container
      this.$el.append(this.actionBar.$el);
    } else {
      // Move actionbar to the original content wrapper
      this.getContentWrapper().append(this.actionBar.$el);
    }
  }

  _toggleActionBarPlaceholder(showPlaceholder) {
    this.ui.actionBarPlaceholder.height(this._getFixedActionBarHeight(showPlaceholder));
    this.ui.actionBarPlaceholder.toggleClass('hidden', !showPlaceholder);
  }
}

module.exports = PageView;
