import {
  Typography as MuiTypography,
  styled,
  type TypographyTypeMap as MuiTypographyTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxTypographyCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  /**
   * If true, text will be truncated with ellipsis if it overflows its container.
   */
  useEllipsis?: boolean
};

export type AxTypographyTypeMap<P = object, D extends React.ElementType = 'span'> = MuiTypographyTypeMap<P & AxTypographyCustomProps, D>;

export type AxTypographyProps<
  D extends ElementType = AxTypographyTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTypographyTypeMap<P & AxTypographyCustomProps, D>, D>;

const styledOptions = {
  name: 'AxTypography',
  shouldForwardProp: (prop: string) => {
    return prop !== 'useEllipsis';
  }
};

const StyledAxTypography = styled(MuiTypography, styledOptions)<AxTypographyProps>(({useEllipsis}) => {
  // Styles are defined in ThemeTypography.ts
  const ellipsisStyles = useEllipsis ? {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  } : {};

  return {
    wordWrap: 'break-word',
    ...ellipsisStyles
  };
});

export const AxTypography: MuiOverridableComponent<AxTypographyTypeMap> = forwardRef(({
  children,
  variant = 'paragraph',
  ...otherProps
}: AxTypographyProps, ref: Ref<HTMLSpanElement>) => {
  const TypographyProps: AxTypographyProps = {
    children,
    variant,
    ...otherProps
  };

  return (
    <StyledAxTypography { ...TypographyProps } ref={ ref }>
      { children }
    </StyledAxTypography>
  );
});

export default AxTypography;

